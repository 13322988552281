import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from '@src/app/services/common.service';
import { Notification, NotificationService } from '@src/app/services/notification.service';
import { WalletStatusService } from '@src/app/services/wallet-status.service';
import { tadaAnimation } from 'angular-animations';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Subject, filter, from, fromEvent, interval, takeUntil } from 'rxjs';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';

@Component({
    selector: 'app-global-header',
    templateUrl: './global-header.component.html',
    styleUrls: ['./global-header.component.less'],
    animations: [tadaAnimation()],
})
export class GlobalHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    $destroy = new Subject();

    searchVisible = true;

    mobileNavVisible = false;

    desktopSearching = false;

    searchAnimate = false;
    searchTooltipVisible = false;

    selectedTab: 'Home' | 'Agents' | 'DayMining' | 'fbtc-dashboard';

    navigationList: any[] = [
        {
            name: 'Home',
            tabName: 'Home',
            link: '/dashboard',
        },
    ];

    animateState = true;

    get isWalletConnected(): boolean {
        return !!this.walletStatusService.currentWallet.getValue();
    }

    get userProfile() {
        return this.walletStatusService.userProfile;
    }

    get userName() {
        return this.commonService.getAddrName(this.walletStatusService.userProfile) || this.walletStatusService.walletAddress;
    }

    get notifications() {
        return this.notificationService.messages;
    }

    get hasUnreadNotifications() {
        return this.notifications?.some(n => !n.is_read);
    }

    constructor(
        public commonService: CommonService,
        public walletStatusService: WalletStatusService,
        private router: Router,
        private drawerService: NzDrawerService,
        private notificationService: NotificationService,
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.$destroy)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (['/home', '/'].includes(event.url.split('?')[0])) {
                    this.searchVisible = false;
                } else {
                    this.searchVisible = true;
                }

                if (event.url?.startsWith('/my-profile')) {
                    this.selectedTab = 'Home';
                } else if (event.url?.startsWith('/hemera/agent/mining-days') || event.url?.startsWith('/hemera/mining-rules')) {
                    this.selectedTab = 'DayMining';
                } else if (event.url?.startsWith('/hemera')) {
                    this.selectedTab = 'Agents';
                } else if (event.url?.startsWith('/coin-dashboard/fbtc')) {
                    this.selectedTab = 'fbtc-dashboard';
                } else {
                    this.selectedTab = null;
                }

                if (event.url?.startsWith('/coin-dashboard/fbtc') || event.url?.startsWith('/my-profile') || event.url?.startsWith('/address')) {
                    if (!localStorage.getItem('header_search_tooltip_visible')) {
                        this.searchAnimate = true;
                    }
                } else {
                    this.searchAnimate = false;
                }
            }
        });

        if (isPlatformBrowser(this.platformId)) {
            // listen to hidden widget
            if (window && window.addEventListener && window['chatWidgetApi']) {
                window.addEventListener('click', () => {
                    window['chatWidgetApi'].showWidget(false);
                });
            }
        }

        this.walletStatusService.checkIfSignupCompleted();
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            from(interval(2000))
                .pipe(
                    filter(() => this.selectedTab !== 'Agents'),
                    takeUntil(this.$destroy)
                )
                .subscribe(() => (this.animateState = !this.animateState));
        }

        fromEvent(this.document.body, 'click')
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
                this.mobileNavVisible = false;
                this.desktopSearching = false;
            });
    }

    ngOnDestroy() {
        this.$destroy.next(null);
        this.$destroy.complete();
    }

    showDropdown() {
        if (this.commonService.isMobile) {
            this.drawerService.create({
                nzContent: ProfileDropdownComponent,
                nzClosable: false,
                nzTitle: null,
                nzFooter: null,
                nzPlacement: 'left',
                nzWidth: '300px',
                nzBodyStyle: {
                    padding: 0,
                },
            });
        }
    }

    navigateByLink(link: string) {
        if (!link) return;

        if (link?.startsWith('http')) {
            window.open(link, '_blank');
        } else {
            this.router.navigateByUrl(link);
        }
    }

    markAsRead(notification: Notification) {
        if (notification.is_read) return;

        notification.is_read = true;
        this.notificationService.markMessageAsRead(notification.id);
    }

    markAllAsRead() {
        this.notifications.forEach(n => {
            n.is_read = true;
            this.notificationService.markMessageAsRead(n.id);
        });
    }

    async login() {
        if (this.walletStatusService.isWalletConnected) {
            this.router.navigate(['/', 'dashboard']);
        } else {
            this.router.navigate(['/', 'home']);
        }
    }

    showDesktopSearching() {
        this.desktopSearching = true;
        this.searchTooltipVisible = false;
    }

    enterSearchBtn() {
        // 如果当前在my-profile页面，或者coin-dashboard/fbtc/xxx页面，并且localStroage中header_search_tooltip_visible 为false，则显示
        if (
            (this.router.url.startsWith('/my-profile') || this.router.url.startsWith('/coin-dashboard/fbtc') || this.router.url.startsWith('/address')) &&
            !localStorage.getItem('header_search_tooltip_visible')
        ) {
            this.searchTooltipVisible = true;
        }
    }

    leaveSearchBtn() {
        this.searchTooltipVisible = false;
    }
}
