import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { LOCAL_STORAGE_KEY_ENUM } from '@shared/types/storage.type';
import { WalletEnum, WalletIconMap, WalletsEnableConnect } from '@shared/types/wallet-chain.type';
import { createIdenticon } from '@src/app/shared/utils/identicon';
import { environment } from '@src/environments/environment';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { Web3Modal } from '@web3modal/ethers/dist/types/src/client';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, filter } from 'rxjs';
import { MyProfileService } from '../modules/my-profile/my-profile.service';
import { MyProfile } from '../shared/types/address.type';
import { isMobileBrowser } from '../shared/utils/browser';
import { CommonService } from './common.service';
import { GtagService } from './gtag.service';
import { NotificationService } from './notification.service';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class WalletStatusService {
    wallets = WalletsEnableConnect;
    walletIconMap = WalletIconMap;

    currentWallet = new BehaviorSubject<{
        wallet: WalletEnum;
        walletAddress: string;
        signature: string;
    }>(null);

    sdnClient: any;

    web3Modal: Web3Modal;

    tokenStr$ = new BehaviorSubject('');

    tokenExp: number; // milliseconds
    userId: string;
    signUpCompleted: boolean;

    selectedWalletChainId: string = null;

    userProfile: MyProfile;
    userProfile$ = new BehaviorSubject<MyProfile>(null);

    localStorage: any;

    msgToSign: string;

    prefix = 'did:pkh:eip155:1:';

    sdnToken: string;
    sdnInfoToSign: {
        did: string;
        message: string;
        updated: any;
        random_server: any;
        address: string;
        sign: string;
    };

    registrationCompleted = true;

    fetchingProfile = false;

    private defaultUserProfileImg: string;

    get walletType() {
        return this.currentWallet.getValue()?.wallet;
    }

    get tokenStr() {
        return this.tokenStr$.getValue();
    }

    get isWalletConnected() {
        return !!this.currentWallet.getValue();
    }

    get walletAddress() {
        return this.currentWallet.getValue()?.walletAddress;
    }

    get wallet() {
        return this.currentWallet.getValue()?.wallet;
    }

    get signature() {
        return this.currentWallet.getValue()?.signature;
    }

    get currentWalletIcon() {
        return this.walletIconMap.get(this.currentWallet.getValue()?.wallet);
    }

    get userProfileImg() {
        return this.userProfile?.profile_image || (this.walletAddress && this.commonService.getAddressIcon(this.walletAddress)) || '/assets/imgs/icon/user.png';
    }
    get userProfileName() {
        return this.commonService.getAddrName(this.userProfile) || this.walletAddress;
    }

    constructor(
        private requestService: RequestService,
        private commonService: CommonService,
        private myProfileService: MyProfileService,
        private gtagService: GtagService,
        private notificationService: NotificationService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.localStorage = window.localStorage;
            this.sdnClient = (window as any).chatWidgetApi?._client;

            this.generateWeb3Modal();
            this.setupInitialState();
            this.commonService.setAddressIconMap([this.walletAddress]);

            this.currentWallet.pipe(filter(data => !!data)).subscribe(async () => {
                if (!this.tokenStr) {
                    try {
                        await this.updateUserToken();
                    } catch (err) {
                        this.commonService.errorMessageByResponse(err as any);

                        setTimeout(() => {
                            this.disconnect();
                        }, 1000);
                    }
                }

                if (this.checkIfSignupCompleted() && !this.fetchingProfile) {
                    this.getWalletProfile();
                }
            });
        }
    }

    getUserId() {
        return this.userProfile?.id || this.decodeJWT(this.tokenStr$.getValue()).user_id;
    }

    setSessionData(wallet?: WalletEnum, walletAddress?: string, signature?: string) {
        if (!walletAddress || !wallet || !signature) {
            this.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET);
            this.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);
            this.currentWallet.next(null);
            this.userProfile = null;
            this.defaultUserProfileImg = null;
            this.tokenStr$.next(null);
        } else {
            this.localStorage.setItem(
                LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET,
                JSON.stringify({
                    wallet,
                    walletAddress,
                    signature,
                })
            );
            this.currentWallet.next({
                wallet: wallet,
                walletAddress,
                signature,
            });
            this.defaultUserProfileImg = createIdenticon(walletAddress.toLowerCase());
            this.commonService.setAddressIconMap([walletAddress]);
        }
    }

    async disconnect(disconnectFromSDN = false) {
        if (this.wallet == WalletEnum.WALLET_CONNECT) {
            await this.web3Modal.disconnect();
        }

        this.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.EMAIL_MODAL_SHOWN);

        if (window.chatWidgetApi && !disconnectFromSDN) {
            this.commonService.info('Logging out from SocialScan...');
            window.chatWidgetApi.logout(() => {
                this.setSessionData();
                if (window.location.pathname?.startsWith('/campaign/')) {
                    window.location.reload();
                } else {
                    window.location.href = '/home';
                }
            });
        } else {
            this.setSessionData();
            if (window.location.pathname?.startsWith('/campaign/')) {
                window.location.reload();
            } else {
                window.location.href = '/home';
            }
        }
    }

    getWalletProfile() {
        this.fetchingProfile = true;

        return this.myProfileService
            .getUserProfile()
            .then(data => {
                this.userProfile = data;
                this.userProfile$.next(data);
                const emailModalShown = this.localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.EMAIL_MODAL_SHOWN);

                this.signUpCompleted = true;

                if (!this.userProfile.email && !emailModalShown) {
                    this.commonService.showUpdateEmailModal('first');
                    this.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.EMAIL_MODAL_SHOWN, '1');
                }

                // 只要更新了 walletProfile，就重新获取 badge/points
                this.myProfileService.getAllBadges();
                this.notificationService.getNotifications();

                if (data.earned_badges?.length) {
                    this.commonService.showGotNewBadgesModal(data.earned_badges);
                }

                this.gtagService.setUserID(data.id);

                return true;
            })
            .catch(err => {
                if (err.status == 401) {
                    this.commonService.error('Authentication expired. Please login again.');
                    this.disconnect();
                } else {
                    console.error(err);
                }

                return false;
            })
            .finally(() => (this.fetchingProfile = false));
    }

    setupInitialState() {
        const walletData = this.localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.CURRENT_WALLET);
        const tokenStr = this.localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TOKEN);

        if (tokenStr) {
            try {
                this.tokenStr$.next(tokenStr);
                this.tokenExp = this.decodeJWT(tokenStr)?.exp * 1000;

                if (this.tokenExp < Date.now()) {
                    this.disconnect();
                }
            } catch (er) {
                this.disconnect();
            }
        }

        if (walletData) {
            try {
                const { wallet, walletAddress, signature } = JSON.parse(walletData);
                this.currentWallet.next({
                    wallet,
                    walletAddress,
                    signature,
                });
                this.defaultUserProfileImg = createIdenticon((walletAddress as string).toLowerCase());
            } catch (er) {
                console.warn('Invalid wallet data in session storage');
            }
        }
    }

    completeRegistration(data: { user_id: string; invitation_code: string; email?: string }) {
        return this.requestService
            .sendRequest<{ auth: string }>({
                method: 'POST',
                url: '/v1/socialscan/user/complete_registration',
                data,
            })
            .then(res => {
                this.tokenStr$.next(res.auth);
                this.signUpCompleted = true;
                this.tokenExp = this.decodeJWT(res.auth)?.exp * 1000;
                this.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TOKEN, res.auth);
                location.href = '/my-profile/dashboard';
            });
    }

    getSDNToken() {
        const currentWallet = this.currentWallet.getValue();
        if (currentWallet) {
            return this.requestService
                .sendRequest<{ auth: string; wallet_address: string; sdn_token: string; user_id: string; registration_complete: boolean }>({
                    method: 'POST',
                    url: '/v1/socialscan/user/login',
                    data: {
                        wallet_address: currentWallet.walletAddress,
                        message: this.msgToSign,
                        signature: currentWallet.signature,
                    },
                })
                .then(data => {
                    const sdnToken = data.sdn_token;
                    this.signUpCompleted = data.registration_complete;

                    if (this.sdnInfoToSign && environment.enableMessage) {
                        this.syncLoginStatusWithSdn({ ...this.sdnInfoToSign, app_token: sdnToken }).catch(() =>
                            this.commonService.error('Failed to sync login status with SDN widget. Please try again later.')
                        );
                    }
                });
        }

        return Promise.resolve(true);
    }

    checkIfSignupCompleted() {
        if (!this.tokenStr$.getValue()) return false;
        try {
            const decodedInfo = this.decodeJWT(this.tokenStr$.getValue());
            this.registrationCompleted = decodedInfo?.registration_complete;
            return decodedInfo?.registration_complete;
        } catch (err) {
            return false;
        }
    }

    async generateWeb3Modal() {
        const projectId = 'c41d849166d6db9d1cea7fc2603554b6';

        const mainnet = {
            chainId: 1,
            name: 'Ethereum',
            currency: 'ETH',
            explorerUrl: 'https://etherscan.io',
            rpcUrl: 'https://cloudflare-eth.com',
        };

        // 3. Create modal
        const metadata = {
            name: 'SocialScan',
            description: 'SocialScan',
            url: 'https://socialscan.io',
            icons: ['https://socialscan.io/favicon.ico'],
        };

        this.web3Modal = createWeb3Modal({
            ethersConfig: defaultConfig({ metadata }),
            chains: [mainnet],
            projectId,
            themeMode: 'light',
        });
    }

    async syncLoginStatusWithSdn(data: {
        did: string;
        message: string;
        updated: string;
        random_server: any;
        address: string;
        sign: string;
        app_token: string;
    }) {
        const identifier = {
            did: data.did,
            address: data.did || `${this.prefix}${data.address}`,
            token: data.sign,
            app_token: data.app_token,
            message: data.message,
        };
        const deviceId = localStorage.getItem('mx_device_id') || null;
        const loginParams = {
            type: 'm.login.did.identity',
            updated: data.updated,
            identifier,
            random_server: data.random_server,
            device_id: deviceId,
        };

        const { access_token, user_id } = await window['chatWidgetApi']._client.DIDLogin(loginParams);
        localStorage.setItem('sdn_access_token', access_token);
        localStorage.setItem('sdn_user_id', user_id);
        localStorage.setItem('sdn_user_address', data.address);
        (window as any).thirdLoginWatch && (window as any).thirdLoginWatch();
    }

    updateUserToken() {
        const currentWallet = this.currentWallet.getValue();
        if (currentWallet) {
            return this.requestService
                .sendRequest<{ auth: string; sdn_token: string; registration_complete: boolean; user_id: string }>({
                    method: 'POST',
                    url: '/v1/socialscan/user/login',
                    data: {
                        wallet_address: currentWallet.walletAddress,
                        message: this.msgToSign,
                        signature: currentWallet.signature,
                    },
                })
                .then(data => {
                    this.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TOKEN, data.auth);
                    this.tokenStr$.next(data.auth);
                    this.tokenExp = this.decodeJWT(data.auth)?.exp * 1000;
                    this.sdnToken = data.sdn_token;
                    this.signUpCompleted = data.registration_complete;

                    if (this.sdnInfoToSign && environment.enableMessage) {
                        this.syncLoginStatusWithSdn({ ...this.sdnInfoToSign, app_token: this.sdnToken }).catch(() =>
                            this.commonService.error('Failed to sync login status with SDN widget. Please try again later.')
                        );
                    }
                });
        }
        return Promise.resolve(true);
    }

    decodeJWT(token: string): any {
        return jwtDecode(token);
    }

    detectWalletsBasedOnDevice() {
        if (isMobileBrowser()) {
            if (window.ethereum && (window.ethereum as any).isMetaMask) {
                return this.wallets.filter(item => item.name === WalletEnum.META_MASK);
            }

            if (window.ethereum && ((window.ethereum as any).isTrust || (window.ethereum as any).isTrustWallet)) {
                return this.wallets.filter(item => item.name === WalletEnum.TRUST_WALLET);
            }

            if (window.ethereum && (window.ethereum as any).isCoinbaseBrowser) {
                return this.wallets.filter(item => item.name === WalletEnum.COINBASE);
            }

            return [WalletEnum.WALLET_CONNECT, WalletEnum.TRUST_WALLET].map(item => this.wallets.find(wallet => wallet.name === item));
        } else {
            return this.wallets;
        }
    }

    getTrustWalletFromWindow() {
        const isTrustWallet = (ethereum: any) => {
            // Identify if Trust Wallet injected provider is present.
            const trustWallet = !!ethereum.isTrust;

            return trustWallet;
        };

        const injectedProviderExist = typeof window !== 'undefined' && typeof window.ethereum !== 'undefined';

        if (!injectedProviderExist) {
            return null;
        }

        if (isTrustWallet(window.ethereum)) {
            return window.ethereum;
        }

        if ((window.ethereum as any)?.providers) {
            return (window.ethereum as any).providers.find(isTrustWallet) ?? null;
        }

        return (window as any)['trustwallet'] ?? null;
    }

    getProvider(chainId?: number) {
        if (this.wallet == WalletEnum.TRUST_WALLET) {
            return this.getTrustWalletFromWindow();
        }

        if (this.wallet == WalletEnum.WALLET_CONNECT) {
            return this.web3Modal.getWalletProvider();
        }

        if (this.wallet === WalletEnum.META_MASK) {
            return (window.ethereum as any)?.providers?.find((item: any) => item.isMetaMask) || window.ethereum;
        }

        if (this.wallet === WalletEnum.COINBASE) {
            const coinbaseWallet = new CoinbaseWalletSDK({
                appName: 'SocialScan',
                darkMode: false,
            });
            return coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', chainId || 1);
        }

        return window.ethereum;
    }

    generateRandomHex(size: number) {
        let result = '';
        for (let i = 0; i < size; i++) {
            result += Math.floor(Math.random() * 256)
                .toString(16)
                .padStart(2, '0');
        }
        return result;
    }

    getMessageToSign() {
        return `Login with this account\n\ntime: ${new Date().toISOString()}\n${this.generateRandomHex(32)}`;
    }
}
