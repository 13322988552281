<div class="search-input">
    <div
        *ngIf="mode === 'desktop'; else mobileTemp"
        nz-dropdown
        [nzDropdownMenu]="searchDropdown"
        [(nzVisible)]="searchDropDownVisible"
        [nzPlacement]="'bottomLeft'"
        [nzTrigger]="null"
        [nzOverlayStyle]="{
            'padding': '20px',
            'border': '1px solid rgba(255,255,255,0.1)',
            'border-radius': '10px',
            'background': '#000',
        }"
    >
        <ng-container *ngTemplateOutlet="searchBox"></ng-container>
    </div>
    <nz-dropdown-menu #searchDropdown="nzDropdownMenu">
        <app-search-result
            [data]="searchResult"
            [mode]="mode"
            class="block"
            style="max-width: calc(100vw - 80px)"
            (clickItem)="searchDropDownVisible = false"
        ></app-search-result>
    </nz-dropdown-menu>

    <ng-template #mobileTemp>
        <div>
            <div
                class="mobile-search-btn w-8 h-8 flex-center-center rounded-full bg-black cursor-pointer hover:bg-opacity-50 text-xl"
                (click)="showMobileSearch()"
            >
                <span nz-icon nzType="antd:search"></span>
            </div>
            <div class="fixed top-0 right-0 bottom-0 left-0 z-[1000] bg-black p-4 pt-8" [@translate]="searchBoxTranslate">
                <div class="flex justify-between items-center gap-x-4">
                    <div class="flex-1">
                        <ng-container [ngTemplateOutlet]="searchBox"></ng-container>
                    </div>

                    <span nz-icon nzType="close-circle" class="text-xl" (click)="hideMobileSearch()"></span>
                </div>
                <div *ngIf="!!searchValue && hasData" class="pt-5">
                    <app-search-result [data]="searchResult" [mode]="mode" (clickItem)="hideMobileSearch()"></app-search-result>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #prefixTemplate>
    <span class="text-xl" nz-icon nzType="antd:search"></span>
</ng-template>
<ng-template #searchBox>
    <nz-input-group [nzSuffix]="prefixTemplate" class="border !border-[#E4F1FB] !border-opacity-80 !px-4 !py-2 !shadow-none">
        <input
            #searchInput
            nz-input
            [(ngModel)]="searchValue"
            nzSize="large"
            [nzBorderless]="true"
            placeholder="Address / Txn Hash..."
            (focus)="searchInputFocus()"
        />
    </nz-input-group>
</ng-template>
